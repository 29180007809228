<template>
  <div class="model page">
    <section class="section">
      <h1 class="title">model</h1>
      <p v-if="option_drive">
        Choose a model and scale the pattern you choosed on your
        <router-link to="/drive">Google Drive</router-link> or drag and drop a
        graphic file (jpg, png, svg) from your computer. All the content and
        files stays in your browser, drive and computer.
      </p>
      <p v-else>
        Choose a model and drag and drop a graphic file (jpg, png, svg) from
        your computer. Then use the rule to scale it. All the content and files
        stays in your browser, drive and computer.
      </p>
    </section>

    <div class="columns is-gapless">
      <div class="column">
        <div class="module-card-wrap">
          <model-card
            v-for="(model, id) in models_loaded"
            :key="model.name"
            :name="model.name"
            :id="id"
            :image="model.image"
            class="module-card"
          ></model-card>
        </div>
      </div>
    </div>

    <abstraktion-footer />
  </div>
</template>

<script>
import AbstraktionFooter from "../components/AbstraktionFooter.vue";
import ModelCard from "../components/ModelCard.vue";
export default {
  name: "ModelList",
  components: { ModelCard, AbstraktionFooter },
  data() {
    return {
      models: [
        "stiletto-white",
        "sneaker",
        "pair-of-thongs",
        "swimsuit-fashion",
        "trousers-classy",
        "notes",
        "cover-book",
        //"shirt",
        "mask",
        "poster-interior",
        //"woman-glasses",
        "interior-walls",
        "cushion-interiors",
        //"tshirt-woman-rock",
        "bra-swimming-pool-woman",
        //"bed",
        //"kendrick-sneaker-black-side",
        //"kendrick-ankle-boots",
      ],
      models_loaded: [],
      option_drive: false,
    };
  },
  mounted() {
    // load models
    this.modelsLoad();
  },
  methods: {
    modelsLoad() {
      this.models.forEach((element) => {
        //console.log(element);

        let img = require("../assets/models/" + element + "/cover.jpg");
        this.models_loaded.push({
          image: img,
          name: element,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.model {
  //background-color: #f7f7f7;
}

.module-card-wrap {
  display: flex;
  flex-wrap: wrap;
}

.module-card {
  min-width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet) {
    width: 48%;
    min-width: 0;
  }

  @media (min-width: $desktop) {
    //width: 30%;
    min-width: 0;
  }

  @media (min-width: $widescreen) {
    //width: 25%;
    min-width: 0;
  }

  @media (min-width: $fullhd) {
    width: 25%;
    min-width: 0;
  }
}
</style>